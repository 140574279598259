import LayoutPages from "@/layout/Layout2";

export default [
  {
    path: "/person",
    component: LayoutPages,
    children: [
      {
        path: "list",
        component: () => import("@/views/person/List"),
      },
      {
        path: "edit",
        component: () => import("@/views/person/AddEditPerson"),
      }
    ],
  },
];
