import LayoutPages from "@/layout/Layout2";

export default [
  {
    path: "/banners",
    component: LayoutPages,
    children: [
      {
        path: "list",
        component: () => import("@/views/banners/List"),
      },
      {
        path: "edit",
        component: () => import("@/views/banners/AddEditBanner"),
      },
    ],
  },
];
