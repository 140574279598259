import LayoutPages from '@/layout/Layout2'

export default [{
    path: '/dashboards',
    component: LayoutPages,
        children: [{
        path: 'admin',
        component: () => import('@/views/dashboards/DashboardAdmin')
    }]
}]
