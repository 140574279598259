import LayoutPages from "@/layout/Layout2";

export default [
  {
    path: "/transparency",
    component: LayoutPages,
    children: [
      {
        path: "listDeclaracionJurada",
        component: () => import("@/views/transparency/ListDeclaracionJurada"),
      },
      {
        path: "editDeclaracionJurada",
        component: () => import("@/views/transparency/AddEditDeclaracionJurada"),
      },
      {
        path: "listDonantes",
        component: () => import("@/views/transparency/ListDonantes"),
      },
      {
        path: "editDonante",
        component: () => import("@/views/transparency/AddEditDonante"),
      },
      {
        path: "listInformeAuditoria",
        component: () => import("@/views/transparency/ListInformeAuditoria"),
      },
      {
        path: "editInformeAuditoria",
        component: () => import("@/views/transparency/AddEditInformeAuditoria"),
      },
      {
        path: "listProyectos",
        component: () => import("@/views/transparency/ListProyectos"),
      },
      {
        path: "listDocumentos",
        component: () => import("@/views/transparency/ListDocumentos"),
      },
      {
        path: "editDocumento",
        component: () => import("@/views/transparency/AddEditDocumento"),
      },
      {
        path: "editData",
        component: () => import("@/views/transparency/EditTransparenciaData"),
      },
    ],
  },
];
