import LayoutPages from '@/layout/Layout2'

export default [{
    path: '/users',
    component: LayoutPages,
        children: [{
        path: 'list',
        component: () => import('@/views/users/List')
        },
        {
        path: 'edit',
        component: () => import('@/views/users/AddEditUser')
    }]
}]
