import LayoutPages from "@/layout/Layout2";

export default [
  {
    path: "/news",
    component: LayoutPages,
    children: [
      {
        path: "list",
        component: () => import("@/views/news/List"),
      },
      {
        path: "edit",
        component: () => import("@/views/news/EditNoticia"),
      },
    ],
  },
];
