<template>
  <div class="router-transitions background-login"  style="background-color:#e3e3e3;background-image:url(img/misc/trama.jpg);background-size: auto !important">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app-layout-blank",
};
</script>
