import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

import globals from "@/globals";

// Layouts
import logins from "./login";
import dashboards from "./dashboards";
import users from "./users";
import banners from "./banners";
import volunteering from "./volunteering";
import news from "./news";
import blog from "./blog";
import work from "./works";
import person from "./persons";
import publication from "./publications";
import transparency from "./transparencys";
import repository from "./repositories";

// import LayoutBlank from '@/layout/LayoutBlank'

Vue.use(Router);
Vue.use(Meta);

const ROUTES = [{ path: "", redirect: "/login" }]
  .concat(logins)
  .concat(dashboards)
  .concat(users)
  .concat(banners)
  .concat(news)
  .concat(blog)
  .concat(work)
  .concat(person)
  .concat(publication)
  .concat(transparency)
  .concat(volunteering)
  .concat(repository);
  
// 404 Not Found page
ROUTES.push({
  path: "*",
  component: NotFound,
});

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: ROUTES,
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add("app-loading");

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

export default router;
