import LayoutPages from "@/layout/Layout2";

export default [
  {
    path: "/volunteering",
    component: LayoutPages,
    children: [
      {
        path: "view",
        component: () => import("@/views/volunteering/View"),
      },
      {
        path: "edit",
        component: () => import("@/views/volunteering/EditGenericData"),
      },
      {
        path: "editActividad",
        component: () => import("@/views/volunteering/EditActividad"),
      },
    ],
  },
];
