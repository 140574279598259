<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'" style="height:79px; padding-right: 1.75rem; padding-left: 1.75rem;">
      <!-- <span class="app-brand-text demo sidenav-text font-weight-normal ml-2">Transparencia</span> -->
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large text-primary d-block ml-2 ml-auto" @click="toggleSidenav()">
        <i id="sidebar-toggler" class="ion ion-md-menu align-middle text-bicentenario"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="ion ion-ios-speedometer" to="/dashboards/admin" v-if="hasRole(['ADM'])" :exact="true">Dashboard</sidenav-router-link>

      <sidenav-router-link icon="fa fa-users" v-if="hasRole(['ADM'])" :active="isMenuActive('/users/list') || isMenuActive('/users/edit')" to="/users/List" :exact="true">Usuarios</sidenav-router-link>
      <sidenav-router-link icon="fa fa-images" v-if="hasRole(['ADM'])" :active="isMenuActive('/banners/list') || isMenuActive('/banners/edit')" to="/banners/List" :exact="true">Banners</sidenav-router-link>

      <sidenav-router-link icon="fa fa-hands-helping" v-if="hasRole(['ADM'])" :active="isMenuActive('/volunteering/list') || isMenuActive('/volunteering/edit')" to="/volunteering/view" :exact="true">Voluntariado</sidenav-router-link>

      <sidenav-router-link icon="fa fa-newspaper" v-if="hasRole(['ADM'])" :active="isMenuActive('/news/list') || isMenuActive('/news/edit')" to="/news/list" :exact="true">Noticias</sidenav-router-link>
      <sidenav-router-link icon="fa fa-blog" v-if="hasRole(['ADM'])" :active="isMenuActive('/blog/list') || isMenuActive('/blog/edit') || isMenuActive('/blog/categorias')" to="/blog/list" :exact="true">Blog</sidenav-router-link>
      <sidenav-router-link icon="fa fa-briefcase" v-if="hasRole(['ADM'])" :active="isMenuActive('/work/list') || isMenuActive('/work/edit') || isMenuActive('/work/lineas')" to="/work/list" :exact="true">Nuestro trabajo</sidenav-router-link>
      <sidenav-router-link icon="fa fa-user-tie" v-if="hasRole(['ADM'])" :active="isMenuActive('/person/list') || isMenuActive('/person/edit')" to="/person/list" :exact="true">Personal</sidenav-router-link>
      <sidenav-router-link icon="fa fa-book-open" v-if="hasRole(['ADM'])" :active="isMenuActive('/publication/list') || isMenuActive('/publication/edit')" to="/publication/list" :exact="true">Publicaciones</sidenav-router-link>
      <sidenav-router-link icon="fa fa-file" v-if="hasRole(['ADM'])" :active="isMenuActive('/repository/list') || isMenuActive('/repository/edit')" to="/repository/list" :exact="true">Repositorio</sidenav-router-link>
      <sidenav-menu v-if="hasRole(['ADM'])" :active="isMenuActive('/transparency/listDeclaracionJurada') || isMenuActive('/transparency/listDocumentos') || isMenuActive('/transparency/listDonantes') || isMenuActive('/transparency/listInformeAuditoria') || isMenuActive('/transparency/listProyectos') || isMenuActive('/transparency/editData') || isMenuActive('/transparency/editInformeAuditoria') || isMenuActive('/transparency/editDonante') || isMenuActive('/transparency/editDocumento') || isMenuActive('/transparency/editDeclaracionJurada')" 
            :open="isMenuOpen('/transparency/listDeclaracionJurada')|| isMenuOpen('/transparency/listDocumentos') || isMenuOpen('/transparency/listDonantes') || isMenuOpen('/transparency/listInformeAuditoria') || isMenuOpen('/transparency/listProyectos')|| isMenuOpen('/transparency/editData') || isMenuActive('/transparency/editInformeAuditoria') || isMenuActive('/transparency/editDonante') || isMenuActive('/transparency/editDocumento') || isMenuActive('/transparency/editDeclaracionJurada')" icon="fa fa-restroom">
        <template slot="link-text">Transparencia</template>
        <sidenav-router-link  to="/transparency/editData" :exact="true">Editar datos</sidenav-router-link>
        <sidenav-router-link  to="/transparency/listDeclaracionJurada" :exact="true" :active="isMenuActive('/transparency/editDeclaracionJurada')">Declaraciones juradas</sidenav-router-link>
        <sidenav-router-link  to="/transparency/listDocumentos" :exact="true" :active="isMenuActive('/transparency/editDocumento')">Documentos</sidenav-router-link>
        <sidenav-router-link  to="/transparency/listDonantes" :exact="true" :active="isMenuActive('/transparency/editDonante')">Donantes</sidenav-router-link>
        <sidenav-router-link  to="/transparency/listInformeAuditoria" :exact="true" :active="isMenuActive('/transparency/editInformeAuditoria')">Informes de auditoría</sidenav-router-link>
        <sidenav-router-link  to="/transparency/listProyectos" :exact="true" :active="isMenuActive('/transparency/editProyecto')">Proyectos</sidenav-router-link>
      </sidenav-menu>
    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    /* eslint-enable vue/no-unused-components */
  },
  data: () => ({
    rolActual: null,
  }),
  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
  },
  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (this.orientation === "horizontal" && (bg.indexOf(" sidenav-dark") !== -1 || bg.indexOf(" sidenav-light") !== -1)) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return `bg-${bg} ` + (this.orientation !== "horizontal" ? "layout-sidenav" : "layout-sidenav-horizontal container-p-x flex-grow-0");
    },
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal";
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    hasRole(roles) {
      if (!roles || roles.length == 0) {
        return true;
      }
      return roles.indexOf(this.rolActual) !== -1;
    },
  },
  mounted: function() {
    this.rolActual = this.$session.get("CodigoRol");
  },
};
</script>
