import layoutHelpers from "@/layout/helpers.js";

export default function() {
  return {
    doLogout() {
      this.$session.destroy();
      this.$router.push("/");
    },
    recaptchaSiteKey: "6LeRTPYaAAAAAI5pl75-rotU1RjrBMR0siRz51fp",
    publicUrl: process.env.BASE_URL,

    // Public url

    apiUrl: process.env.NODE_ENV === 'production' ? '//api.transparencia.org.pe/api/' : 'http://localhost:44027/api/',
    filesUrl: process.env.NODE_ENV === 'production' ? '//api.transparencia.org.pe/' : 'http://localhost:44027/',

    //  apiUrl: "http://localhost:44027/api/",
    //  filesUrl: "http://localhost:44027/",

    // Layout helpers
    layoutHelpers,

    fileUrl(path) {
      if (path) return this.filesUrl + path;
      else return this.publicUrl + "img/misc/usuario.png";
    },

    apiRoute(controller, action) {
      return this.apiUrl + controller + "/" + action;
    },

    apiResource(path) {
      return this.filesUrl + path;
    },

    /* VARIABLES APP */
    tipoContenidoArticulo: [
      { codigo: "NOC", titulo: "Sin contenido multimedia" },
      { codigo: "SIM", titulo: "Simple" },
      { codigo: "IMG", titulo: "Imagenes múltiples" },
      { codigo: "VID", titulo: "Videos múltiples" },
      { codigo: "IVM", titulo: "Imágenes y videos múltiples" },
    ],
    arrMeses: [
      { codigo: "ENERO", titulo: "ENERO" },
      { codigo: "FEBRERO", titulo: "FEBRERO" },
      { codigo: "MARZO", titulo: "MARZO" },
      { codigo: "ABRIL", titulo: "ABRIL" },
      { codigo: "MAYO", titulo: "MAYO" },
      { codigo: "JUNIO", titulo: "JUNIO" },
      { codigo: "JULIO", titulo: "JULIO" },
      { codigo: "AGOSTO", titulo: "AGOSTO" },
      { codigo: "SETIEMBRE", titulo: "SETIEMBRE" },
      { codigo: "OCTUBRE", titulo: "OCTUBRE" },
      { codigo: "NOVIEMBRE", titulo: "NOVIEMBRE" },
      { codigo: "DICIEMBRE", titulo: "DICIEMBRE" },
    ],
    getTipoContenidoArticulo(codigo) {
      var resultado = this.tipoContenidoArticulo.filter((x) => x.codigo == codigo);
      if (resultado != null) {
        return resultado[0].titulo;
      }
      return "";
    },
    tipoPersona: [
      { codigo: "CDR", titulo: "Consejo Directivo 2020 - 2023" },
      { codigo: "ADA", titulo: "Asamblea de Asociados" },
      { codigo: "EQU", titulo: "Equipo" },
      { codigo: "VOC", titulo: "Vocales" },
    ],
    getTipoPersona(codigo) {
      var resultado = this.tipoPersona.filter((x) => x.codigo == codigo);
      if (resultado != null) {
        return resultado[0].titulo;
      }
      return "";
    },
    tipoPersonaEstado: [
      { codigo: "HAB", titulo: "Habilitado" },
      { codigo: "CON", titulo: "Con licencia" },
    ],
    getTipoPersonaEstado(codigo) {
      var resultado = this.tipoPersonaEstado.filter((x) => x.codigo == codigo);
      if (resultado != null) {
        return resultado[0].titulo;
      }
      return "";
    },
    ambitoTransparencia: [
      { codigo: "NAC", titulo: "Nacional" },
      { codigo: "INT", titulo: "Internacional" },
    ],
    getAmbitoTransparencia(codigo) {
      var resultado = this.ambitoTransparencia.filter((x) => x.codigo == codigo);
      if (resultado != null) {
        return resultado[0].titulo;
      }
      return "";
    },
    arrTipoMonedas: [
      { codigo: "PEN", titulo: "Soles", simbolo: "S/" },
      { codigo: "USD", titulo: "Dólar", simbolo: "$" },
    ],
    getTipoMoneda(codigo) {
      var resultado = this.arrTipoMonedas.filter((x) => x.codigo == codigo);
      if (resultado != null && resultado.length > 0) {
        return resultado[0];
      }
      return "";
    },
    arrEstadoProyecto: [
      { codigo: "EJE", titulo: "EJECUCIÓN" },
      { codigo: "FIN", titulo: "FINALIZADO" },
    ],
    getEstadoProyecto(codigo) {
      var resultado = this.arrEstadoProyecto.filter((x) => x.codigo == codigo);
      if (resultado != null && resultado.length > 0) {
        return resultado[0];
      }
      return "";
    },

    arrTipoContenido: [
      { codigo: "IMG", titulo: "IMAGEN" },
      { codigo: "VID", titulo: "VIDEO" },
    ],
    getTipoContenido(codigo) {
      var resultado = this.arrTipoContenido.filter((x) => x.codigo == codigo);
      if (resultado != null && resultado.length > 0) {
        return resultado[0];
      }
      return "";
    },

    /** FIN VARIABLES APP */

    // Check for RTL layout
    get isRtlMode() {
      return document.documentElement.getAttribute("dir") === "rtl" || document.body.getAttribute("dir") === "rtl";
    },

    // Check if IE
    get isIEMode() {
      return typeof document.documentMode === "number";
    },

    // Check if IE10
    get isIE10Mode() {
      return this.isIEMode && document.documentMode === 10;
    },

    // Layout navbar color
    get layoutNavbarBg() {
      return "navbar-theme";
    },

    // Layout sidenav color
    get layoutSidenavBg() {
      return "sidenav-theme";
    },

    // Layout footer color
    get layoutFooterBg() {
      return "footer-theme";
    },

    // Animate scrollTop
    scrollTop(to, duration, element = document.scrollingElement || document.documentElement) {
      if (element.scrollTop === to) return;
      const start = element.scrollTop;
      const change = to - start;
      const startDate = +new Date();

      // t = current time; b = start value; c = change in value; d = duration
      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          element.scrollTop = to;
        }
      };

      animateScroll();
    },
  };
}
