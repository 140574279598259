<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
    <!-- Navbar toggle -->
    <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center " v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="toggleSidenav">
        <i id="navbar-sm-sidebar-toggler" class="ion ion-md-menu text-white text-large align-middle" />
      </a>
    </b-navbar-nav>
    <b-navbar-toggle target="app-layout-navbar">
      <template #default="{ expanded }">
        <i v-if="expanded" class="text-white fas fa-ellipsis-v"></i>
        <i v-else class="text-white fas fa-ellipsis-v"></i>
      </template>
    </b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <hr class="d-lg-none w-100 my-2" />
      <b-navbar-nav class="align-items-lg-center">
        <!-- Search -->
        <img :src="`${publicUrl}img/misc/logo-transparencia.png`" style="width: 78px; height: auto" />
      </b-navbar-nav>
      <b-navbar-nav class="align-items-lg-center ml-auto">
        <!-- Divider -->
        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>
        <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle text-white">
              <img :src="`${ publicUrl }/img/misc/usuario.png`" class="d-block ui-w-30 rounded-circle" />
              <strong class="px-1 mr-lg-2 ml-2 ml-lg-0 text-white"
                >{{ $session.get("Nombres") }} {{ $session.get("ApellidoPaterno") }}
                <br />
                <small>{{ $session.get("RolNombre") }}</small>
              </strong>
            </span>
          </template>
          <b-dd-item @click="doLogoutWeb"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Cerrar sesión</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "app-layout-navbar",

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    doLogoutWeb() {
      this.$session.destroy();
      this.$router.push("/");
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
  },
};
</script>
