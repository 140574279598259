import LayoutPages from "@/layout/Layout2";

export default [
  {
    path: "/blog",
    component: LayoutPages,
    children: [
      {
        path: "list",
        component: () => import("@/views/blog/List"),
      },
      {
        path: "edit",
        component: () => import("@/views/blog/AddEditBlog"),
      },
      {
        path: "categorias",
        component: () => import("@/views/blog/ListCategorias"),
      },
    ],
  },
];
