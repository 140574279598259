import LayoutPages from "@/layout/Layout2";

export default [
  {
    path: "/work",
    component: LayoutPages,
    children: [
      {
        path: "list",
        component: () => import("@/views/work/List"),
      },
      {
        path: "edit",
        component: () => import("@/views/work/AddEditWork"),
      },
      {
        path: "lineas",
        component: () => import("@/views/work/ListLineas"),
      },
    ],
  },
];
