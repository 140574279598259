<template>
  <nav class="layout-footer footer" :class="getLayoutFooterBg()">
    <div class="container-fluid container-p-x pb-3">
      <a target="_blank" href="https://www.stratlabs.pe" class="footer-link pt-3">@Developed by <strong style="color:#0E89CD">StratLabs S.A.C.</strong></a>
    </div>
  </nav>
</template>

<script>
export default {
  name: "app-layout-footer",

  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`;
    },
  },
};
</script>
