import "custom-event-polyfill";
import "url-polyfill";

import Vue from "vue";
import App from "./App";
import router from "./router";

import BootstrapVue from "bootstrap-vue";
import VueResource from "vue-resource";
import VueSession from "vue-session";

import globals from "./globals";
import baseMixin from "./baseMixin";

import Popper from "popper.js";
import moment from "moment";

import Swal from "sweetalert2";

// Required toLowerCase() enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(VueSession, {
  persist: true,
});

Vue.http.interceptors.push(function(request, next) {
  if (request.url.toLowerCase().indexOf("login") === -1 && request.url.toLowerCase().indexOf("sendpasswordtemporal") === -1 && request.url.toLowerCase().indexOf("changepassword") === -1) {
    if (this.$session.get("jwt") != null) {
      request.headers.set("X-CSRF-TOKEN", "TOKEN");
      request.headers.set("Authorization", "Bearer " + this.$session.get("jwt"));
    } else {
      this.$router.push("/");
    }
  }
  var vm = this;
  next(function(response) {
    if (response.status === 401 && response.body.data === "GEN-TOKEN-EXPIRED") {
      Swal.fire({
        title: "",
        icon: "warning",
        text: "La sesión ha expirado. Por favor ingrese nuevamente.",
        confirmButtonText: "Iniciar sesión",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onOpen: () => Swal.hideLoading(),
      }).then(function() {
        vm.$router.push("/");
      });
      return null;
    }
    return response;
  });
});

Vue.filter("date", function(value, format) {
  if (value) {
    return moment(String(value)).format(format || "DD/MM/YYYY");
  }
});

Vue.filter("longdate", function(value, format) {
  if (value) {
    return moment(String(value)).format(format || "dddd DD [de] MMMM");
  }
});

Vue.filter("datetime", function(value, format) {
  if (value) {
    return moment(String(value)).format(format || "DD/MM/YYYY h:mm A");
  }
});

Vue.filter("time", function(value, format) {
  if (value) {
    return moment(String(value)).format(format || "h:mm A");
  }
});

Vue.filter("upper", function(value) {
  if (!value) return "";
  return value.toUpperCase();
});
Vue.filter("lower", function(value) {
  if (value) {
    return value.toLowerCase();
  }
});

// Vue.filter('subStr', function (value) {

// })

// Global RTL flag
Vue.mixin({
  data: globals,
});

Vue.mixin(baseMixin());

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
