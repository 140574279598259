import Vue from "vue"
import Swal from 'sweetalert2'
import moment from "moment"

export default function () {

    var globalTranslation = {};
    globalTranslation['MSG-GUARDAR-DEFECTO'] = "Guardado.";


    Vue.filter('date', function (value, format) {
        if (value) {
            return moment(String(value)).format(format || 'DD/MM/YYYY')
        }
    });

    Vue.filter('datetime', function (value, format) {
        if (value) {
            return moment(String(value)).format(format || 'DD/MM/YYYY h:mm A')
        }
    });

    Vue.filter('time', function (value, format) {
        if (value) {
            return moment(String(value)).format(format || 'h:mm A')
        }
    });

    Vue.filter('lower', function (value) {
        if (value) {
            return value.toLowerCase();
        }
    });

    return {
        data: function () {
            return {
                //fecha: '2019-01-05',//new Date(2019,00,04),//
                languageDefault: null,
                countryDefault: {
                    id: null,
                    name: null,
                    code: null,
                    defaultLang: null
                },
                languages: [],
                defaultCopiedIndicator: '<i class="fa fa-check fa-fw"></i>',
                timeouts: {
                    copyHtmlToClipboard: {},
                },
                defaultErrorMessage: 'Ha ocurrido un error al intentar realizar el registro.',
            }
        }, methods: {
            getGuid: function () {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            },
            cloneObject: function (obj) {
                return JSON.parse(JSON.stringify(obj));
            },
            toDictionary: function (list, key) {
                var dict = {};

                if (!list)
                    return dict;

                for (var i = 0; i < list.length; i++) {
                    dict[list[i][key]] = list[i];
                }

                return dict;
            },
            toArrayDictionary: function (list, key) {
                var dict = {};

                if (!list)
                    return dict;

                for (var i = 0; i < list.length; i++) {
                    if (!dict[list[i][key]])
                        dict[list[i][key]] = [];

                    dict[list[i][key]].push(list[i]);
                }

                return dict;
            },
            clearArrayItems: function (list) {
                if (list) {
                    list.splice(0, list.length);
                }
            },
            removeArrayItem: function (list, item) {
                return list.filter(x => x != item);
            },
            refreshArrayItem: function (list, item, index = null) {
                var indexOfItem = index || list.indexOf(item);
                list.splice(indexOfItem, 1, item)
                return list;
            },
            convertModelToFormData: function (data = {}, form = null, namespace = '') {
                let files = {};
                let model = {};
                for (let propertyName in data) {
                    if (Object.prototype.hasOwnProperty.call(data, propertyName) && data[propertyName] instanceof File) {
                        files[propertyName] = data[propertyName]
                    } else {
                        model[propertyName] = data[propertyName]
                    }
                }

                model = JSON.parse(JSON.stringify(model))
                let formData = form || new FormData();

                for (let propertyName in model) {
                    if (!Object.prototype.hasOwnProperty.call(model, propertyName) || !model[propertyName]) continue;
                    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
                    if (model[propertyName] instanceof Date)
                        formData.append(formKey, model[propertyName].toISOString());
                    else if (model[propertyName] instanceof File) {
                        formData.append(formKey, model[propertyName]);
                    }
                    else if (model[propertyName] instanceof Array) {
                        model[propertyName].forEach((element, index) => {
                            const tempFormKey = `${formKey}[${index}]`;
                            if (typeof element === 'object') this.convertModelToFormData(element, formData, tempFormKey);
                            else formData.append(tempFormKey, element.toString());
                        });
                    }
                    else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
                        this.convertModelToFormData(model[propertyName], formData, formKey);
                    else {
                        formData.append(formKey, model[propertyName].toString());
                    }
                }

                for (let propertyName in files) {
                    if (Object.prototype.hasOwnProperty.call(files, propertyName)) {
                        formData.append(propertyName, files[propertyName]);
                    }
                }
                return formData;
            },

            initParsley: function () {
                window.Parsley.addMessages("es", {
                    defaultMessage: "Este valor parece ser inválido.",
                    type: {
                        email: "Este valor debe ser un correo válido.",
                        url: "Este valor debe ser una URL válida.",
                        number: "Este valor debe ser un número válido.",
                        integer: "Este valor debe ser un número válido.",
                        digits: "Este valor debe ser un dígito válido.",
                        alphanum: "Este valor debe ser alfanumérico.",
                    },
                    notblank: "Este valor no debe estar en blanco.",
                    required: "Este valor es requerido.",
                    pattern: "Este valor es incorrecto.",
                    min: "Este valor no debe ser menor que %s.",
                    max: "Este valor no debe ser mayor que %s.",
                    range: "Este valor debe estar entre %s y %s.",
                    minlength:
                        "Este valor es muy corto. La longitud mínima es de %s caracteres.",
                    maxlength:
                        "Este valor es muy largo. La longitud máxima es de %s caracteres.",
                    length:
                        "La longitud de este valor debe estar entre %s y %s caracteres.",
                    mincheck: "Debe seleccionar al menos %s opciones.",
                    maxcheck: "Debe seleccionar %s opciones o menos.",
                    check: "Debe seleccionar entre %s y %s opciones.",
                    equalto: "Este valor debe ser idéntico.",
                });

                window.Parsley.setLocale("es");
            },
            copyHtmlToClipboard: async function (containerCopyId, indicatorElementId, elementHtmlCopied) {
                let el = document.getElementById(containerCopyId);
                var body = document.body,
                    range,
                    sel;
                if (document.createRange && window.getSelection) {
                    range = document.createRange();
                    sel = window.getSelection();
                    sel.removeAllRanges();
                    try {
                        range.selectNodeContents(el);
                        sel.addRange(range);
                    } catch (e) {
                        range.selectNode(el);
                        sel.addRange(range);
                    }
                    document.execCommand("copy");
                } else if (body.createTextRange) {
                    range = body.createTextRange();
                    range.moveToElementText(el);
                    range.select();
                    range.execCommand("Copy");
                }

                if (!indicatorElementId) {
                    await this.sweetAlert("Los datos han sido copiados.");
                } else {
                    if (this.timeouts.copyHtmlToClipboard[indicatorElementId]) {
                        clearTimeout(this.timeouts.copyHtmlToClipboard[indicatorElementId]);
                    }

                    var element = document.getElementById(indicatorElementId);
                    var elementHtmlNormal = element.innerHTML;
                    element.innerHTML = elementHtmlCopied;

                    this.timeouts.copyHtmlToClipboard[indicatorElementId] = setTimeout(function () {
                        element.innerHTML = elementHtmlNormal;
                    }, 2000);
                }
            },
            /*
            convertModelToFormData: function (data = {}, form = null, namespace = '') {
                let files = {};
                let model = {};
                for (let propertyName in data) {
                    if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File) {
                        files[propertyName] = data[propertyName]
                    } else {
                        model[propertyName] = data[propertyName]
                    }
                }

                model = JSON.parse(JSON.stringify(model))
                let formData = form || new FormData();

                for (let propertyName in model) {
                    if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
                    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
                    if (model[propertyName] instanceof Date)
                        formData.append(formKey, model[propertyName].toISOString());
                    else if (model[propertyName] instanceof File) {
                        formData.append(formKey, model[propertyName]);
                    }
                    else if (model[propertyName] instanceof Array) {
                        model[propertyName].forEach((element, index) => {
                            const tempFormKey = `${formKey}[${index}]`;
                            if (typeof element === 'object') this.convertModelToFormData(element, formData, tempFormKey);
                            else formData.append(tempFormKey, element.toString());
                        });
                    }
                    else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
                        this.convertModelToFormData(model[propertyName], formData, formKey);
                    else {
                        formData.append(formKey, model[propertyName].toString());
                    }
                }

                for (let propertyName in files) {
                    if (files.hasOwnProperty(propertyName)) {
                        formData.append(propertyName, files[propertyName]);
                    }
                }
                return formData;
            },*/
            fetchParametrosPorGrupo: async function (grupo, list, transformFunc, completeFunc) {

                var $this = this;
                var url = this.apiRoute('Common', 'ListParametros');
                var options = {};
                options['code'] = grupo;

                await this.$http.get(url, { params: options }).then(function (response) {
                    var parameterList = [];
                    var parameterResponse = response.body.data;
                    list.splice(0, list.length);
                    for (var i = 0; i < parameterResponse.length; i++) {
                        var item = parameterResponse[i];
                        if (transformFunc)
                            item = transformFunc(item);

                        parameterList.push(item);
                        list.push(item);
                    }
                    if (completeFunc)
                        completeFunc();
                    //list = parameterList;

                }, function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'Ha ocurrido un error al obtener los datos. Por favor refresque la ventana para intentar nuevamente.',
                    });
                    return null;
                });
            },
            fetchApi: function (code, parameters, list, transformFunc) {
                var $this = this;
                var url = '@Url.Action("Dispatch","DynamicApi",new { Area="" })';
                this.fetchRequests += 1;
                var options = parameters;
                options['code'] = code;

                this.$http.get(url, { params: options }).then(function (response) {
                    list.splice(0, list.length);
                    for (var i = 0; i < response.data.data.length; i++) {
                        var item = response.data.data[i];
                        if (transformFunc)
                            item = transformFunc(item);

                        list.push(item);
                    }
                    $this.fetchRequests -= 1;
                }, function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'Ha ocurrido un error al obtener los datos. Por favor refresque la ventana para intentar nuevamente.',
                    });

                });
            },
            fetchPaginatedApi: function (code, parameters, pagination, list, transformFunc) {
                var $this = this;
                var url = '@Url.Action("Dispatch","DynamicApi",new { Area="" })';
                this.fetchRequests += 1;
                var options = parameters;
                options['code'] = code;
                options['paginated'] = true;
                options['page'] = pagination.page;
                options['pageSize'] = pagination.pageSize;

                this.$http.get(url, { params: options }).then(function (response) {
                    list.splice(0, list.length);
                    for (var i = 0; i < response.data.data.length; i++) {
                        var item = response.data.data[i];
                        if (transformFunc)
                            item = transformFunc(item);

                        list.push(item);
                    }

                    pagination.totalCount = response.data.totalCount,
                        pagination.pageSize = response.data.pageSize,
                        pagination.page = response.data.page,
                        pagination.pageCount = response.data.pageCount,
                        pagination.hasNextPage = response.data.hasNextPage,
                        pagination.hasPrevPage = response.data.hasPrevPage,

                        $this.fetchRequests -= 1;
                }, function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'Ha ocurrido un error al obtener los datos. Por favor refresque la ventana para intentar nuevamente.',
                    });

                });
            },
            showLoading: function (toggle = true, mensaje) {
                // if (toggle) {
                //     Swal.fire({
                //         text: mensaje || 'Procesando',
                //         allowOutsideClick: false,
                //         onOpen: () => Swal.showLoading(),
                //         confirmButtonColor: '#ff7177'
                //     });
                // }
                // else {
                //     Swal.close();
                // }
            },

            sweetConfirm: async function (texto) {
                var result = await Swal.fire({
                    title: "",
                    text: texto || "¿Desea realizar esta acción?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Continuar",
                    cancelButtonText: "Cancelar",
                })

                return result.isConfirmed;
            },

            sweetAlert: async function (texto, icono) {
                var result = await Swal.fire({
                    title: "",
                    icon: icono == undefined ? 'warning' : icono,
                    text: texto,
                    confirmButtonText: "Aceptar",
                })

                return result;
            },

            procesarRespuestaGuardarDefecto: function (response, successRedirect, successFunction) {
                var vm = this;
                if (response.body.error) {
                    if (response.body.isValidationError) {
                        Swal.fire({
                            icon: 'warning',
                            title: '',
                            text: response.body.message || response.body.mensaje,
                        });
                    }
                    else if (response.body.isExceptionError) {
                        Swal.fire({
                            icon: 'error',
                            title: '',
                            text: response.body.message || response.body.mensaje,
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: '',
                            text: response.body.message || response.body.mensaje,
                        });
                    }
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: response.body.message || response.body.mensaje || globalTranslation['MSG-GUARDAR-DEFECTO'],
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            if (successFunction) {
                                successFunction();
                            }
                            else if (successRedirect) {

                                if (successRedirect === "reload") {
                                    window.location.reload();
                                }
                                else if (successRedirect === true || successRedirect === "") {
                                    window.location.reload();
                                }
                                else {
                                    window.location = successRedirect;
                                }
                            }
                        }
                    });
                }
            },

            procesarRespuestaErrorDefecto: function (response) {
                /*
                    Para utilizar de la forma: if(!this.procesarRespuestaErrorDefecto(response)){ código si no ocurrió error }

                */

                if (response.body.error) {
                    if (response.body.isValidationError) {
                        Swal.fire({
                            icon: 'warning',
                            title: '',
                            text: response.body.message || response.body.mensaje,
                        });
                    }
                    else if (response.body.isExceptionError) {
                        Swal.fire({
                            icon: 'error',
                            title: '',
                            text: response.body.message || response.body.mensaje,
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: '',
                            text: response.body.message || response.body.mensaje,
                        });
                    }

                    return true;
                }

                return false;
            },

            procesarRespuestaExcepcionDefecto: function (response, blockUIContentId) {
                var vm = this;
                if (response.status == 401) //No autorizado
                {
                    Swal.fire({
                        icon: 'warning',
                        title: response.body.title || 'No autorizado',
                        text: response.body.message,
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: response.body.title || 'Error',
                        text: response.body.message,
                    });
                }

            },

        },
        watch: {

        },
        mounted: function () {
        }
    }
}