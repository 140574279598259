import LayoutPages from "@/layout/Layout2";

export default [
  {
    path: "/publication",
    component: LayoutPages,
    children: [
      {
        path: "list",
        component: () => import("@/views/publication/List"),
      },
      {
        path: "edit",
        component: () => import("@/views/publication/AddEditPublicacion"),
      },
      {
        path: "categorias",
        component: () => import("@/views/publication/ListCategorias"),
      },
    ],
  },
];
